@import 'settings';

body {
	margin: 0;
	padding: 0;
	@include hp_font($type-1, 400, black);
}

// Header

a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}

header {
	border-top: 7px solid $misztal_dark-blue;
}

.logo {
	display: inline-block;
}

.logoa {
	padding: 10px 0;
	@media (max-width: $mediaMedium) {
		img {
			width: 250px;
		}
	}

	@media (max-width: $mediaSmall) {
		img {
			width: 190px;
		}
	}

}

.cbp-spmenu, .cbp-spmenu-push {
	margin-top: 0;
}


.cbp-spmenu-right.cbp-spmenu-open {
	padding-left: 0;
	padding-top: 20px;
	margin-top: 0;
}

.cbp-spmenu-vertical a {
	border-bottom: none;
}

.menu {
	border-left: 1px solid $misztal_blue;
}

.menu li {
	border-bottom: 1px solid $misztal_blue;
}

.menu li:last-child {
	border-bottom: none;
}

@media (min-width: $mediaXsmall) {

	.menu-toggle {
		display: none;
	}

	.menu {
		display: inline-block;
		float: right;
		border-left: none;
	}

	.menu li {
		border-bottom: none;
	}

	.cbp-spmenu {
		a {
			@media (max-width: $mediaMedium) {
				@include hp_font($type-1, 700, $misztal_grey);
			}
			transition: color .5s;
			color: $misztal_grey;
			padding: 10px;
			font-weight: bold;
			&:hover {
				color: $misztal_dark-blue;
				text-decoration: none;
				background: transparent;
			}
			&:visited {
				text-decoration: none;
			}
			&:focus {
				text-decoration: none;
			}
		}
		background: transparent;
		position: static;
		padding-left: 0;
		padding-top: 25px;
	}

	.cbp-spmenu li {
		display: inline-block;
	}

	.cbp-spmenu-vertical {
		a {
			border-bottom: none;
		}
		width: auto;
	}
}

@media (max-width: $mediaXsmall) {
	.cbp-spmenu {
		border-left: 1px solid $misztal_blue;
		padding-top: 20px;
		background: $misztal_dark-blue;
		a {
			padding: 15px;
		}
	}
}

@media (min-width: $mediaXsmall) and (max-width: $mediaSmall) {
	.cbp-spmenu {
		a {
			padding: 5px;
		}
	}
}

.cbp-spmenu .active {
	color: $misztal_dark-blue;
	@media (max-width: $mediaXsmall) {
		background: $misztal_blue;
	}
}



// Hamburger

.menu-toggle {
	position: absolute;
	top: 20px;
	right: 5px;
	width: 65px;
	padding-left: 20px;
	height: 45px;
	cursor: pointer;
}

.hamburger,
.hamburger::before,
.hamburger::after {
	content: '';
	display: block;
	transform: translateY(20px);
	height: 3px;
	width: 35px;
	background: $misztal_dark-blue;
	transition: all ease-in-out 500ms;
}

.hamburger::before {
	transform: translateY(-8px);
}

.hamburger::after {
	transform: translateY(5px);
}

.open .hamburger {
	transform: rotate(45deg);
	margin-top: 20px;
}

.open .hamburger::before {
	opacity: 0;
}

.open .hamburger::after {
	transform: translateY(-2px) rotate(-90deg);
}



// Main

.main-photo {
	overflow: hidden;
}

.main-photo img {
	width: 100%;
	@media (max-width: $mediaSmall) {
		width: auto;
		height: 260px;
	}
}

.quote {

	padding: 20px 0 30px;
	position: relative;
	img {
		margin-top: 30px;
		position: absolute;
	}
	background-color: $misztal_dark-blue;
	p {
		@include hp_font($type-2, 400, $misztal_white);
		margin-left: 70px;
		line-height: 35px;
		float: right;
	}
	.blue-arrow {
		display: block;
		margin-left: 50%;
		transform: translateY(-4px);
		transform: translateX(-50%);
	}
}

.services {
	margin: 60px 0 40px;
	text-align: center;
	.service {
		margin-bottom: 0px;
	}
	a {

		text-decoration: none;
		&:hover h3 {
			background-color: $misztal_blue;
			color: $misztal_dark-blue;
		}
	}
	h3 {
		@include hp_font($type-4, 500, $misztal_white);
		transition: all .5s;
		background-color: $misztal_dark-blue;
		margin: 0;
		padding: 30px 0;
	}
	img {
		width: 100%;
	}
	p {
		text-align: left;
		color: $misztal_grey;
	}
	.view-more {
		text-align: center;
		@include hp_font($type-1, 600, $misztal_dark-blue);
		letter-spacing: 1px;
	}
}

.view-more {
	text-align: center;
	@include hp_font($type-1, 600, $misztal_dark-blue);
	letter-spacing: 1px;
}

.line {
	height: 3px;
	width: 60px;
	background-color: $misztal_blue;
	margin-left: 50%;
	transform: translateX(-50%);
}

.about-us {
	padding-bottom: 50px;
	color: $misztal_white;
	background-color: $misztal_dark-blue;
	h2 {
		text-align: center;
		@include hp_font($type-5, 500, $misztal_white);
		padding: 10px 0;
	}
	img {
		margin-left: 50%;
		transform: translateX(-50%);
		margin-top: -7px;
	}
	p {
		line-height: 25px;
		margin-bottom: 40px;
	}
	a {
		transition: all .5s;
		&:hover {
			text-decoration: none;
			color: $misztal_dark-blue;
		}

		text-align: center;
		padding: 20px;
		display: block;
		width: 150px;
		margin: 0 auto;
		background-color: $misztal_blue;
		@include hp_font($type-2, 500, $misztal_white);
	}
}

.realisations {
	margin-bottom: 50px;
	img {
		width: 100%;
	}
	h2 { 
		text-align: center;
		@include hp_font($type-5, 500, $misztal_dark-blue);
		padding: 25px 0;
	}
}

.realisation {
	padding-bottom: 30px;
}

// Contact

.contact {
	@media (max-width: $mediaXsmall) {
		text-align: center;
	}
	margin-top: 20px;
	padding-bottom: 50px;
	color: $misztal_white;
	background-color: $misztal_blue;
	h2 {
		text-align: center;
		@include hp_font($type-5, 500, $misztal_dark-blue);
		padding: 10px 0;
	}

	p {
		padding-bottom: 5px;
		margin: 0;
		@include hp_font($type-6, 500, $misztal_dark-blue);
	}

	.col-md-3 .col-sm-6{
		padding: 0;
	}

	.white-arrow {
		margin-left: 50%;
		transform: translateX(-50%);
		margin-top: -7px;
		@media (max-width: $mediaXsmall) {
			margin-left: auto;
			transform: none;
			-webkit-transform: none;
		}
	}
}



.contact-column {
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 150px;
	@media (max-width: $mediaXsmall) {
		min-height: 10px;
	}
}

.contact .contact-column-address {
	padding-top: 10px;
	@include hp_font($type-1, 500, $misztal_white);
	img {
		padding-right: 10px;

	}
}

.contact-link {
	transition: all .5s;
	width: 100%;
	display: block;
	text-align: center;
	background-color: $misztal_dark-blue;
	padding: 20px 0;
	&:hover {
		color: $misztal_blue;
	}
}

.map-link {
	margin-bottom: 22px;	
}

.map-container {
	height: 135px;
	overflow: hidden;
}

#map {
	margin-top: -40px;
	height: 230px;
	width: 100%;
}
// Footer 

footer {
	a {
		transition: all .5s;
	}
	background-color: $misztal_dark-blue;
	p {
		text-align: center;
		color: $misztal_white;
		padding: 10px 0;

	}
	a:hover {
		color: $misztal_blue;
	}
}

@import 'services';
@import 'about-references';
@import 'contact';

