.about-references-wrapper {
	margin: 40px 0;
	h3 {
		@include hp_font($type-4, 500, $misztal_dark-blue);
	}
	li {
		color: #7a7a7a;
		text-align: left;
	}
	.author {
		text-align: right;
		font-weight: bold;
	}
}