// Contact-Form

.form-title {
	overflow: hidden;
}

.contact-form {
	padding-top: 40px;
	background: $misztal_blue;
}

.contact {
	@media (max-width: $mediaSmall) {
		padding-bottom: 50px;
	}
}

.contact-form .row {
	@media (min-width: $mediaSmall) and (max-width: $mediaMedium) {
		
	}
	box-sizing: border-box;
	color: $misztal_white;
	letter-spacing: 2px;
	padding: 60px 0;
	ul {
		@media (max-width: $mediaSmall) {
			text-align: center;
		}
		list-style: none;
		padding-top: 0;
		padding-left: 0;
		margin-top: 0;
		li:last-child p {
			padding-left: 0px;
		}
		li {
			img {
				width: 30px;
				margin-top: -5px;
			}
			.capital {
				padding-left: 5px;
				height: 30px;
				width: auto;
			}

			p {
				@media (max-width: $mediaSmall) {
					display: block;
				}
				display: inline-block;
				padding-left: 15px;
			}
		}
	}
}

.contact {
	//	margin-bottom: 40px;
}

textarea {
	@media (min-width: $mediaSmall) and (max-width: $mediaMedium) {
		@include hp_font($type-1, 500, $misztal_white);
	}
	height: 120px;
	width: 100%;
	border: none;
	background-color: $misztal_dark-blue;
	padding: 10px;
	letter-spacing: 1.5px;
}

textarea::placeholder {
	color: $misztal_white;
	letter-spacing: 1.5px;
	width: 100%;
}

input::placeholder {
	color: $misztal_white;
	letter-spacing: 1.5px;	
}

input {
	float: left;
	@media (min-width: $mediaSmall) and (max-width: $mediaMedium) {
		@include hp_font($type-1, 500, $misztal_white);
	}
	margin-bottom: 5%;
	display: inline-block;
	width: 47%;
	@media (max-width: 480px) {
		width: 100%;
	}
	border: none;
	background-color: $misztal_dark-blue;
	padding: 10px;
	letter-spacing: 1.5px;
	box-sizing: border-box;
}

.float-right {
	float: right;
}

#submit {
	margin-top: 5%;
	float: right;
//	margin-bottom: 0;
//	width: 30%;
	background-color: #8a5c9b;
	font-weight: bold;
//	@media (max-width: $mediaXsmall) {
//		width: 47%;
//		display: block;
//		margin: 5% auto 0;
//	}
//	@media (max-width: 480px) {
//		float: none;
//	}
}

#file {
	margin-top: 5%;
}

.form p {
	display: inline-block;	
}

.contact-column-bottom {
	margin-bottom: 40px;
}


// MAP

.contact-map .map-container {
	height: 380px;
	overflow: hidden;
}

.contact-map #map {
	margin-top: 0;
	height: 380px;
	width: 100%;
}

.required-field {
	float: right;
	text-align: left;
}
