.headline {
	padding: 15px 0;
	background-color: $misztal_dark-blue;
	h1 {
		@include hp_font($type-8, 500, $misztal_white);
		margin-left: 30px;
	}
}

.services-wrapper {
	margin-bottom: 50px;
}

.wrapper {
	margin: 20px 0;
	h2 {
		@include hp_font($type-4, 500, $misztal_dark-blue);
	}
	p {
		color: $misztal_grey;
		line-height: 20px;
	}
	@media (max-width: $mediaXsmall) {
		text-align: center;
		p {
			text-align: left;
		}
	}
}

.wrapper-photo {
	margin-top: 30px;
}

