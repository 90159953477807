$pixelBase : 16;
@mixin hp_font($size, $weight, $color:#000) {
	color: $color;
	font: $weight (($size * 10) / $pixelBase) + rem/((($size + 0.2) * 10) / $pixelBase) + rem 'Play', sans-serif;
}

$misztal_blue: #42b6db;
$misztal_dark-blue: #123356;
$misztal_white: #fff;
$misztal_grey: #7a7a7a;


$type-1: 1.5;
$type-2: 2.1;
$type-3: 1.3;
$type-4: 2.3;
$type-5: 2.8;
$type-6: 1.9;
$type-7: 1.4;
$type-8: 3.4;



$mediaLarge: 		1400px;
$mediaMedium:	    1200px;
$mediaSmall:        992px;
$mediaXsmall:       767px;



@media (min-width: 992px) {
	.container {
		max-width: 900px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}




